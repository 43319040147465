import axios from "axios"

export default{
    namespaced: true,
    
    state: {
        primaryColor: process.env.VUE_APP_THEME_COLOR_PRIMARY,
        colorDarken: process.env.VUE_APP_THEME_COLOR_DARKEN,
        schoolName: process.env.VUE_APP_THEME_SCHOOL,
        academicYearId: null,
        academicTermId: null,
        academicYear: null,
        term: null,
        modal: false,
        modalIcon: null,
        modalTitle: null,
        modalMessage: null,
        appOverlay: false,
        currentAcademicPeriod: null,          
    },

    getters: {
        getPrimaryColor(state){
            return state.primaryColor
        },
        getColorDarken(state){
            return state.colorDarken
        },
        getSchoolName(state){
            return state.schoolName
        },
        getAcademicYearId(state){
            return state.academicYearId
        },
        getAcademicTermId(state){
            return state.academicTermId
        },
        getAcademicYear(state){
            return state.academicYear
        },
        getTerm(state){
            return state.term
        },
        getModal(state){
            return state.modal
        },
        getModalTitle(state){
            return state.modalTitle
        },
        getModalIcon(state){
            return state.modalIcon
        },
        getModalMessage(state){
            return state.modalMessage
        },
        getAppOverlay(state){
            return state.appOverlay
        },
        
        getCurrentAcademicPeriod (state) {
            return state.currentAcademicPeriod
        }, 
    },

    mutations: {
        setAcademicYearId(state, value){
            state.academicYearId = value
        },
        setAcademicTermId(state, value){
            state.academicTermId = value
        },
        setAcademicYear(state, value){
            state.academicYear = value
        },
        setTerm(state, value){
            state.term = value
        },
        setModal(state, value){
            state.modal = value
        },
        setModalIcon(state, value){
            state.modalIcon = value
        },
        setModalTitle(state, value){
            state.modalTitle = value
        },
        setModalMessage(state, value){
            state.modalMessage = value
        },
        setAppOverlay(state, value){
            state.appOverlay = value
        },
        
        setCurrentAcademicPeriod (state, value) {
            state.currentAcademicPeriod = value
        },
    },

    actions: {
        getAcademicPeriod(){
            let url = '/api/current-period';
            return axios.get(url);
        },         
        displayModal({ commit }, payload){
            commit('setModal',true);
            commit('setModalMessage',payload.response.data.message);
            commit('setModalTitle', payload.response.statusText);
            commit('setModalIcon', payload.icon);
        }       
    }
}