<template>
    <v-row
        justify="center"
    >
        
        <v-col>        
            <v-card
                class="mx-auto pa-0"
                width="1080"
                height="80vh"
            >
                <v-card-title
                    class="d-flex primary--text py-2 px-0"
                >
                    <v-spacer></v-spacer>
                    <div class="caption font-weight-bold primary--text text-uppercase">Mark Sheet</div>
                    <v-spacer></v-spacer>
                    <v-btn
                        text                
                        x-small
                        color="red"
                        @click="closeMarkSheet"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>            
                </v-card-title>
                
                <v-row class="px-4">
                    <v-col class="py-0">
                        <Header/>
                        <v-divider></v-divider>
                        <MarkTable/>
                        <v-row class="mt-3 mr-4">
                            <v-spacer></v-spacer>
                            <div class="caption">
                                Total Students: {{ totalStudents }}
                            </div>
                        </v-row>
                    </v-col>
                </v-row>
                
                <v-overlay
                    :absolute="absolute"
                    :value="markSheetOverlay"                    
                    color="grey lighten-5"
                    opacity="0.9"
                    class="pa-5"
                >                    
                    <Lesson/>
                    <v-expand-transition> 
                        <v-card
                            v-show="expandAssesment"
                            class="mx-auto"
                            max-width="300px"
                            light
                        >
                            <v-card-text                            
                                class="pa-4"
                            >
                                <v-list 
                                    light              
                                >
                                    <v-list-item-group v-model="assesmentSelected">
                                        <v-list-item
                                            v-for="(item, i) in assesments"
                                            :key="i"
                                            @click="setAssesment(item)"
                                        >
                                        
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.assesment }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card-text>    
                        </v-card>
                    </v-expand-transition>        
                    <v-progress-circular 
                        transition="fade-transition" 
                        indeterminate 
                        size="64"
                        v-show="markSheetLoading"
                        color="blue"
                    ></v-progress-circular> 
                </v-overlay>
               
            </v-card>
        </v-col>
        
    </v-row>    
</template>

<script>
import Header from './MarkSheetHeader'
import Lesson from './MarkSheetLesson'
import MarkTable from './MarkSheetTable'
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'MarkSheet',
    created(){
        this.initialize();
    },
    components: {
        Header,
        Lesson,
        MarkTable,
    },
    computed: {
        ...mapGetters({            
            markSheetOverlay: 'markSheet/getMarkSheetOverlay',
            markSheetLoading: 'markSheet/getMarkSheetLoading',
            totalStudents: 'markSheet/getTotalStudents',
            expandAssesment: 'markSheet/getExpandAssesment',  
        }),
       
    },
    data: () => ({
        absolute: true,             
        userTimetable: [],
        assesments: [
            {id: 2, assesment: 'Mid Term Assesment'},
            {id: 1, assesment: 'End of Term Assesment'},
        ],
        assesmentSelected: {},
    }),
    methods: {
        ...mapMutations({
            mainMenu: 'dashboard/setMainMenu',           
            overlay: 'markSheet/setMarkSheetOverlay',
            expand: 'markSheet/setExpandLessons',
            setExpandAssesment: 'markSheet/setExpandAssesment',
            welcomeMessage: 'dashboard/setWelcomeMessage',
            setMarkSheetLoading: 'markSheet/setMarkSheetLoading',
            setAssesmentType: 'markSheet/setAssesment',                   
        }),

        initialize(){
            this.overlay(true);
            this.expand(false);
            this.setExpandAssesment(false);
            setTimeout(() => {
                this.setExpandAssesment(true);
            },500)
            // this.setMarkSheetLoading(true);            
        },              
        closeMarkSheet(){
            this.overlay(true);
            this.setMarkSheetLoading(true);           
            setTimeout(() => {
                this.expand(true);
            },1000)         
        },

        setAssesment(assesment){ 
            this.setAssesmentType(assesment.id);
            this.setExpandAssesment(false);
            setTimeout(() => {
                this.expand(true);
            },500)
        }
    }
}
</script>