<template>
    <v-dialog
        v-model="dialog"
        width="700"
        persistent
    >
        <v-card>
            <v-card-title class="text-h5 green lighten-2">
                Post Term Reports
            </v-card-title>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="terms"
                    :loading="loading"
                    fixed-header
                    height="50vh"
                >
                    <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title></v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search Term"
                                single-line
                                hide-details
                            ></v-text-field>
                        </v-toolbar>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn
                            color="primary"
                            dark
                            @click="editViewTerm(item)"
                        >
                            Edit / View
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>

            <v-card-actions class="px-6">
                <v-spacer></v-spacer>
                <v-btn
                    text
                    outlined
                    @click="close"
                >
                    Close
                </v-btn>
            </v-card-actions>
            
            <v-overlay
                :absolute="true"
                :value="overlay"
            >
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    props: {
        dialogOpen: Boolean,
    },

    created: function () {
        this.initialize();
    },

    data: function () {
        return {
            overlay: false,
            dialog: this.dialogOpen,
            terms: [],
            headers: [
                { text: 'Year', value: 'year' },
                { text: 'Term', value: 'term' },
                { text: 'Report', value: 'report' },                
                { text: 'Edit', value: 'actions' },
            ],
            search: '',
            loading: false,
        }
    },

    methods: {
        async initialize () {
            this.loading = true;
            try {
                const { data } = await this.getTermReports();
                // console.log(data);
                this.mapTermReports(data);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.loading = false;
        },

        mapTermReports (data) {
            let report = null;
            this.terms = [];
            // console.log(data);
            data.forEach(record => {
                // console.log(data[record]);
                Object.keys(record).forEach(key => {
                    // console.log(data[record][r].report)
                    report = record[key].report == 2 ? 
                    'Mid Term' : 'End of Term';
                    this.terms.push({
                        year: record[key].year,
                        term: record[key].term,
                        report: report,
                    })
                })
               
            })

            // console.log(this.terms);
        },

        ...mapActions({
            getTermReports: 'settings/getTermReports',
        }),

        editViewTerm (item) {
            console.log(item)
        },

        close () {
            this.$emit('dialog-close');
        }
    }
}
</script>