<template>
     <v-row>           
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="950"
            >
                <v-card-title>
                    Edit / View Students                   
                    
                    <v-spacer></v-spacer>

                    <v-dialog
                        v-model="dialog"
                        max-width="500px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                outlined
                                text
                                v-bind="attrs"
                                v-on="on"
                                class="mr-3"
                            >
                                Add New Student
                            </v-btn>
                        </template>
                        <v-card ref="form">
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>                        
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                ref="first_name"
                                                v-model="editedItem.first_name"
                                                label="First Name"
                                                hide-details="auto"
                                                :rules="rules"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                ref="last_name"
                                                v-model="editedItem.last_name"
                                                label="Last Name"
                                                hide-details="auto"
                                                :rules="rules"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">                                            
                                            <v-menu
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"                   
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="date"
                                                    label="Date of Birth"                                                    
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    hide-details
                                                ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    ref="picker"
                                                    v-model="date"                                                
                                                    min="1950-01-01"
                                                    @change="saveDate"                                                
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="editedItem.birth_certificate_pin"
                                                label="Birth Certificate Pin"
                                                hide-details
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-select
                                                v-model="editedItem.gender"
                                                :items="gender"
                                                label="Gender"
                                                hide-details
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                ref="form_class_id"
                                                v-model="editedItem.form_class_id"
                                                :items="formClasses"
                                                item-text="id"
                                                item-value="id"
                                                label="Form Class"
                                                hide-details="auto"
                                                :rules="rules"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-container>    
                            </v-card-text> 
                            <v-card-actions
                                class="px-4"
                            >
                                <span
                                    v-show="saved"
                                >
                                    Data saved
                                    <v-icon
                                        small
                                        color="primary"                                        
                                        class="ml-3"
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </span>

                                <span
                                    v-show="error"
                                    class="red--text"
                                >
                                    {{ errorMessage }}
                                    <v-icon
                                        small
                                        color="red"
                                        class="ml-3"
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </span>

                                <v-spacer></v-spacer>
                                <v-btn                                    
                                    text
                                    @click="close"
                                >
                                    Close
                                </v-btn>
                                <v-btn
                                    color="primary"                                    
                                    dark                                   
                                    @click="save"
                                >
                                    Save
                                </v-btn>                                
                            </v-card-actions>
                            <v-overlay
                                :value="overlay"
                                opacity="0.8"
                                z-index="10"
                                absolute                    
                            >
                                
                                <v-progress-circular
                                    indeterminate
                                    size="64"
                                ></v-progress-circular>                   
                            </v-overlay>
                        </v-card>       
                    </v-dialog>

                    <students-upload
                       v-on:update-students="initialize"
                    ></students-upload>
                    
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="students"
                        fixed-header                        
                        height="45vh"
                        :calculate-widths="true" 
                        :search="search"
                        :loading="loading"
                        :expanded.sync="expanded"
                        item-key="student_id"
                    >
                        <template v-slot:top>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                class="mb-8"
                                clearable
                            ></v-text-field>
                            <v-dialog
                                v-model="dialogDelete"
                                max-width="430px"
                                persistent
                            >
                                <v-card
                                    v-if="confirmDelete"
                                >
                                    <v-card-title>{{ deleteStudentSelected }}</v-card-title>
                                    <v-card-text>                                        
                                        <v-select
                                            :items="statuses"
                                            v-model="status"
                                            label="Status"                                            
                                            item-text="detail"
                                            item-value="id"
                                            autofocus
                                            hide-details
                                            ref="student-status"
                                            clearable
                                            open-on-clear
                                        ></v-select>
                                    </v-card-text>
                                    <v-card-actions
                                        class="px-6"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="closeDelete"
                                            outlined
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="deleteStudentConfirm"
                                            outlined
                                        >
                                            Delete
                                        </v-btn>                                        
                                    </v-card-actions>
                                </v-card>
                                <v-expand-transition>
                                    <v-card
                                        v-show="deleting"
                                        color="primary"
                                        dark
                                        class="pa-4"
                                    >
                                        <v-card-text>
                                        {{ studentDeleted }}
                                        <v-progress-linear
                                                indeterminate
                                                color="white"
                                                class="mb-0 mt-4"
                                        ></v-progress-linear>
                                        </v-card-text>
                                    </v-card>
                                </v-expand-transition>
                            </v-dialog>
                        </template>

                        <template  v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                tile
                                color="primary"
                                @click="editStudent(item)"
                                class="mr-6"
                                small
                            >
                                Edit
                                <v-icon
                                    small
                                >
                                    mdi-pencil
                                </v-icon>
                                
                            </v-btn>
                            <v-btn
                                text
                                outlined
                                @click="deleteStudent(item)"
                                small
                            >    
                                Delete
                                <v-icon
                                    small                                    
                                >
                                    mdi-delete
                                </v-icon>
                                
                            </v-btn>    
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <span>Birth Certificate Pin:</span>
                                <span>{{ item.birth_certificate_pin }}</span>
                                <v-spacer></v-spacer>
                                <span>Date of Birth: </span>
                                <span>{{ item.date_of_birth }}</span>
                            </td>
                        </template>

                    </v-data-table>
                    <v-snackbar
                        v-model="snack"
                        :timeout="3000"
                        color="primary"
                    >
                        {{ snackText }}
                        <template v-slot:action="{ attrs }">
                            <v-btn
                                v-bind="attrs"
                                text
                                @click="snack = false"
                            >
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-card-text>                
            </v-card>            
        </v-col>
     </v-row>            
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import StudentsUpload from './StudentsUpload.vue';
export default {
    components: {         
        StudentsUpload, 
    },

    created () {
        this.initialize();
    },

    data: () => ({
        headers: [
             {text: 'ID', align: 'start', sortable: 'false', value: 'student_id', width: '50'},
             {text: 'First Name', align: 'start', sortable: 'false', value: 'first_name', width: '50'},
             {text: 'Last Name', align: 'start', sortable: 'false', value: 'last_name', width: '50'},
             {text: 'Class', align: 'start', sortable: 'false', value: 'form_class_id', width: '50'},
             {text: 'Gender', align: 'start', sortable: 'false', value: 'gender', width: '40'},
             {text: '', align: 'start', sortable: 'false', value: 'actions', width: '100'},
             {text: '', value: 'data-table-expand', width: '20'},
        ],
        students: [],
        search: '',
        loading: true,
        snack: false,
        snackText: '',
        options: {
            itemsPerPage: -1,
        },
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: ''
        },
        deletedItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: ''
        },
        defaultItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: ''
        },
        gender: ['M', 'F'],
        formClasses: [],
        menu: false,
        date: null,
        expanded: [],
        singleExpand: false,
        overlay: false,
        saved: false,
        error: false,
        rules: [
            value => !!value || 'Required'
        ],
        formHasErrors: false,
        errorMessage: '',
        statuses: [],
        status: 3,
        deleteStudentSelected: null,
        confirmDelete: true,
        deleting: false,
    }),

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Student' : 'Edit Student'
        },
        studentDeleted () {
            return 'Deleting ' + this.deletedItem.first_name + ' ' + this.deletedItem.last_name;
        }
    },

    watch: {
        dialog (val) {
            val || this.close()
        },
        dialogDelete (val) {
            val || this.closeDelete()
        },
        menu (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
        date (val) {
            this.editedItem.date_of_birth = val;
        }
    },

    methods: {
        ...mapActions({
            getStudents: 'subjectAssignments/getCurrentStudents',
            getFormClasses: 'termReports/getFormClassesList',
            postStudent: 'editViewStudents/postStudent',
            getStudentStatus: 'editViewStudents/getStudentStatus',
            delete: 'editViewStudents/deleteStudent',
        }),
        ...mapMutations({
            setStudent: 'editViewStudents/setStudent',
        }),
        async initialize(){
            try {
                const studentsPromise = this.getStudents();
                const formClassesPromise = this.getFormClasses();
                const studentStatusPromise = this.getStudentStatus();

                const studentList = await studentsPromise;
                const formClasses = await formClassesPromise;
                const studentStatuses = await studentStatusPromise;                
                               
                this.students = studentList.data;
                this.formClasses = formClasses.data;
                studentStatuses.data.forEach(record => {
                    if(record.id > 2){
                        this.statuses.push(record);
                    }
                })
                //console.log(this.statuses);               
                this.loading = false;
            } catch (error) {
                console.log(error);
                console.log(error.response);
            }
        },
        editStudent(item){
            //console.log(`Edit ${ item }`);
            this.editedIndex = this.students.indexOf(item);
            this.editedItem = Object.assign({}, item);
            console.log(item);
            this.date = item.date_of_birth;
            this.dialog = true;
        },
        close () {
            this.dialog = false;
            this.error = false;
            this.saved = false;
            this.date = null;
            this.$nextTick( () => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            Object.keys(this.editedItem).forEach(f => {
                if(f === 'first_name' || f === 'last_name' || f === 'form_class_id'){
                    this.$refs[f].reset()
                }
                
            })
        },
        async save () {
            this.overlay = true
            console.log(this.editedItem);
            this.setStudent(this.editedItem);
            this.formHasErrors = false;
            //console.log(Object.keys(this.editedItem));
            Object.keys(this.editedItem).forEach( f => {
                if(f === 'first_name' && !this.editedItem[f]){
                    this.formHasErrors = true;
                    this.$refs[f].validate(true);
                } 
                if(f === 'last_name' && !this.editedItem[f]){
                    this.formHasErrors = true;
                    this.$refs[f].validate(true);
                } 
                if(f === 'form_class_id' && !this.editedItem[f]){
                    this.formHasErrors = true;
                    this.$refs[f].validate(true);
                } 
               
            })
            if(!this.formHasErrors){
                console.log('fields present');
                try {
                    let response = await this.postStudent();
                    console.log(response);
                    let studentsPromise = await this.getStudents();
                    this.students = studentsPromise.data;
                    //console.log(response);
                    this.error = false;
                    this.saved = true;  
                } catch (error) {
                    console.log(error.response);
                    this.errorMessage = 'Error Occured';
                    this.saved = false; 
                    this.error = true;                    
                }
            }
            else{
                //console.log('fields empty');
                this.saved = false; 
                this.error = true;
                this.errorMessage = 'Required Fields Empty'; 
            }
            
            this.overlay = false;
                     
        },
        deleteStudent(item){
            //console.log(item);           
            this.deletedItem = Object.assign({}, item);
            this.deleteStudentSelected = item.first_name + ' ' + item.last_name;
            this.dialogDelete = true;
            this.confirmDelete = true;
            //console.log(this.$refs);
            // this.$nextTick(() => {
            //     console.log(this.$refs['student-status'])
            //     //this.$refs['student-status'].isActive = true;
            // })
           
        },
        addStudent(){
            //
        },
        saveDate(date){
            this.$refs.menu.save(date);
        },
        closeDelete(){
            this.dialogDelete = false;
            this.status = 3;            
        },
        async deleteStudentConfirm(){
            console.log('deleting...');
            this.confirmDelete = false;
            setTimeout(() => {
                this.deleting = true;
            });
            
            //console.log(this.deletedItem);
            this.setStudent({
                student_id: this.deletedItem.student_id,
                student_status_id: this.status
            });
            try {
                let response = await this.delete();
                console.log(response);
                response = await this.getStudents();
                this.students = response.data;                
                this.deleting = false;
                setTimeout(() =>{
                this.dialogDelete = false;                 
                })
                setTimeout(() => {
                    this.snack = true;
                    this.snackText = this.deletedItem.first_name + ' ' + this.deletedItem.last_name + ' deleted.'
                })                                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                setTimeout(() =>{
                this.dialogDelete = false;                 
                })
                setTimeout(() => {
                    this.snack = true;
                    this.snackText = 'Error Occurred.'
                }) 
            }
                      
            
        }
    }
}
</script>